<template>
  <v-container fluid class="grey lighten-5 fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="4" md="6" sm="8">
        <v-card class="pa-4">
          <v-card-title class="justify-center">
            <img
              src="../assets/img/logo_fqm_preta.svg"
              alt="Logo FQM"
              width="200px"
            />
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation class="pa-4">
            <v-text-field
              v-model="form.email"
              :rules="rules.emailRules"
              label="E-mail"
              required
              outlined
              placeholder="Insira seu em-mail"
              :error-messages="error ? messageError : formError.email"
              :validate-on-blur="true"
              @blur="formError.email = null"
              :error="error"
              color="fmq_black"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              required
              outlined
              placeholder="Digite sua senha"
              label="Senha"
              :rules="rules.passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :error-messages="error ? messageError : formError.password"
              :validate-on-blur="true"
              @blur="formError.password = null"
              :error="error"
              color="fmq_black"
            ></v-text-field>
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="fmq_black"
              outlined
              class="mr-4"
              @click="login"
              block
            >
              Entrar
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rules } from "@/utils/rules";
import { loginUser } from "@/services/login";
import { getUserMeusDados } from "@/services/user";
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    valid: true,
    error: false,
    form: {
      email: "",
      password: "",
    },
    showPassword: false,
    loading: false,
    alertError: false,
    messageError: "",
    formError: {},
  }),

  methods: {
    ...mapActions(["setUserData", "setUserLoggedIn"]),
    validate() {
      this.$refs.form.validate();
    },
    async login() {
      this.loading = true;
      await setTimeout(async () => {
        if (this.formValid) {
          try {
            const data = await loginUser({
              grant_type: "password",
              client_id: process.env.VUE_APP_CLIENT_ID,
              client_secret: process.env.VUE_APP_CLIENT_SECRET,
              username: this.form.email,
              password: this.form.password,
            });
            window.localStorage.token = `Bearer ${data.accessToken}`;
            this.setUserLoggedIn(true);
            const response = await getUserMeusDados();
            this.setUserData(response.data);
            this.$router.push({ path: "/" });
          } catch (e) {
            this.loading = false;
            this.alertError = true;
            this.messageError = e.response.data.message;
            this.error = true;
            this.formError = e.response.data.erro;
          }
        } else {
          this.loading = false;
        }
      }, 500);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
  created() {
    this.rules = rules;
  },
};
</script>
